import { useCallback, useState } from 'react';
import { registerCustomer } from 'services/UserManagementService';
import { CustomerData } from 'types/models';

export function useCustomerRegistration() {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();
  const [data, setData] = useState<
    | {
        readonly id: string;
        readonly otpNonce: string;
      }
    | {
        readonly id: string;
        readonly hint: string;
        readonly status: string;
      }
  >();

  const register = useCallback(async (customerData: CustomerData) => {
    setLoading(true);

    try {
      const response = await registerCustomer(customerData);
      const result = await response.json();

      if (response.ok) {
        setData(result);
      } else {
        setError(result);
      }
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    isLoading,
    error,
    data,
    register
  };
}
