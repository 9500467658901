import {
  useAppInsightsContext,
  useTrackEvent
} from '@microsoft/applicationinsights-react-js';
import { parseCountryCode } from '@pmi.web/countries';
import { PrimaryButton } from '@pmi.web/react-common';
import { CustomerRegistrationForm } from '@pmi.web/react-user-forms';
import { Disclaimer } from 'components/common/Disclaimer/Disclaimer';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { useRegistrationContext } from 'contexts/registration-context/useRegistrationContext';
import { useBusinessLogicErrorsHandler } from 'hooks/useBusinessLogicErrorsHandler';
import { useCustomerRegistration } from 'hooks/useCustomerRegistration';
import { useProspectOnboard } from 'hooks/useProspectOnboard';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { startSignIn } from 'services/AuthService';
import { RegistrationStartupType } from 'types/models';

import { SelectCustomerCountry } from './SelectCustomerCountry';

export function CustomerRegistrationV2() {
  const {
    t,
    i18n: { language }
  } = useTranslation();
  const appInsights = useAppInsightsContext();
  const {
    redirectUrl,
    prospectData,
    resumeToken,
    registrationFlow,
    availableCountries
  } = useRegistrationContext();

  const [formData, setFormData] = useState({
    ...prospectData,
    locale: language,
    address: {
      ...prospectData?.address,
      country: prospectData?.address?.country ?? ''
    }
  });

  const {
    data: registrationResult,
    error: registrationError,
    isLoading: isLoadingCustomerRegistration,
    register
  } = useCustomerRegistration();

  const {
    data: onboardingResult,
    error: prospectCreationError,
    isLoading: isLoadingProspectOnboarding,
    onboard
  } = useProspectOnboard();

  const businessLogicErrors = useBusinessLogicErrorsHandler(
    registrationError,
    prospectCreationError
  );

  const uniqueKey = useMemo(() => {
    // Currently, the form state is inside the component. If some validation
    // outside of it fails, it has no information about it. These failures
    // are "businessLogicErrors". To ensure the component gets rendered
    // correctly, we set a unique key and update it when the errors change.
    return (Math.floor(Math.random() * 99999999) + 1).toString();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessLogicErrors, formData.address.country]);

  const isLoading =
    isLoadingCustomerRegistration || isLoadingProspectOnboarding;
  const result = registrationResult || onboardingResult;

  const trackCustomerRegistrationDone = useTrackEvent(
    appInsights,
    'CustomerRegistrationDone',
    {}
  );

  useEffect(() => {
    if (result && 'otpNonce' in result) {
      trackCustomerRegistrationDone({});
      appInsights.core.flush(false);

      let shopUrl: string | undefined;

      if (registrationFlow === RegistrationStartupType.ShopSpecific) {
        const countryShop = availableCountries?.find(
          country =>
            country.countryCode.toLowerCase() ===
            formData.address.country.toLowerCase()
        );

        if (countryShop) {
          shopUrl = countryShop.shopUrl;
        }
      }

      startSignIn({
        nonceToken: result.otpNonce,
        prospectId: result.id,
        currentState: JSON.stringify({
          redirectUrl: redirectUrl,
          shopUrl: shopUrl,
          locale: language
        })
      });
    }
  }, [
    appInsights.core,
    availableCountries,
    formData.address.country,
    language,
    redirectUrl,
    registrationFlow,
    result,
    trackCustomerRegistrationDone
  ]);

  useEffect(() => {
    setFormData(state => ({ ...state, locale: language }));
  }, [language]);

  useEffect(() => {
    if (result && 'status' in result && result.status === 'NeedsResumption') {
      const emailRecoverUrl = new URL('/', window.location.origin);
      emailRecoverUrl.searchParams.append('prospectId', result.id);
      emailRecoverUrl.searchParams.append('lang', language);

      if (redirectUrl) {
        emailRecoverUrl.searchParams.append('redirectUrl', redirectUrl);
      }

      window.location.assign(emailRecoverUrl.toString());
    }
  }, [language, redirectUrl, result]);

  return (
    <>
      {(registrationError || prospectCreationError) &&
        Object.keys(businessLogicErrors).length === 0 && (
          <div className="mb-md">
            <Disclaimer type="error">
              <h3>{t('Something went wrong')}</h3>
              <p>{t('Please contact our support team.')}</p>
            </Disclaimer>
          </div>
        )}

      {isLoading ||
        (result && (
          <div className="min-h-[150px] flex items-center justify-center">
            <LoadingSpinner />
          </div>
        ))}

      {!isLoading && !result && (
        <>
          {registrationFlow === RegistrationStartupType.ShopSpecific && (
            <div className="mb-md">
              <SelectCustomerCountry
                activeCountry={parseCountryCode(formData.address.country)}
                onChange={country => {
                  setFormData({
                    ...prospectData,
                    locale: language,
                    address: {
                      ...prospectData?.address,
                      country
                    }
                  });
                }}
              />
            </div>
          )}

          <CustomerRegistrationForm
            showSponsorSelection={
              registrationFlow === RegistrationStartupType.ShopSpecific
            }
            disableEmailChanges={!!resumeToken}
            key={uniqueKey}
            country={parseCountryCode(formData.address.country)}
            language={formData.locale}
            formId="customer-registration-form"
            onSubmit={data => {
              const prospect = {
                ...formData,
                ...data
              };

              setFormData(prospect as any);

              if (registrationFlow === RegistrationStartupType.ProspectOnly) {
                onboard(prospect, resumeToken);
              } else {
                register(prospect);
              }
            }}
            defaultValues={formData}
            businessLogicErrors={businessLogicErrors}
          />

          <div className="w-full flex justify-end mt-md">
            <PrimaryButton
              disabled={isLoading}
              type="submit"
              form="customer-registration-form"
            >
              {t('Submit')}
            </PrimaryButton>
          </div>
        </>
      )}
    </>
  );
}
